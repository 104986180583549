import { ReactEChartsComponent } from '../../../components/ReactEChartComponent';
import * as echarts from 'echarts';

const InvoicedChartComponent = props => {
	const { data, dashboard } = props;

	const { dates, values } = data;

	const option = {
		title: {
			show: false,
		},
		tooltip: {
			show: true,
			trigger: 'item',
			axisPointer: {
				type: 'none',
			},
		},
		grid: {
			top: '5%',
			left: '0%',
			right: '0%',
			width: dashboard ? '96%' : '97%',
			height: dashboard ? '70%' : '95%',
			containLabel: true,
		},
		xAxis: {
			type: 'category',
			boundaryGap: false,
			data: dates,
			axisLine: {
				show: true,
			},
			axisLabel: {
				color: 'rgba(255, 255, 255, 0.5)',
				fontSize: 8,
				margin: 10,
				align: 'center',
			},
		},

		yAxis: {
			type: 'value',
			boundaryGap: false,
			axisLabel: {
				show: true,
				verticalAlign: 'bottom',
				fontSize: 8,
			},
			splitLine: {
				lineStyle: {
					type: 'dashed',
					color: 'rgba(255, 255, 255, 0.29)',
					width: 1,
				},
			},
		},
		series: [
			{
				type: 'line',
				smooth: true,
				lineStyle: {
					width: 0,
				},
				showSymbol: values?.length === 1,
				symbolSize: 6,
				itemStyle: {
					color: 'rgba(28, 245, 161,1)',
				},
				areaStyle: {
					opacity: 0.8,
					color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
						{
							offset: 0,
							color: 'rgba(28, 245, 161,1)', // Color completo (arriba)
						},
						{
							offset: 1,
							color: 'rgba(28, 245, 161,1)', // Transparente (abajo)
						},
					]),
				},
				emphasis: {
					focus: 'series',
				},
				data: values,
			},
		],
		graphic: {
			type: 'text',
			left: 'center',
			top: '60px',
			style: {
				text: values.length === 0 ? 'There is no data' : null,
				fontSize: 14,
				fill: '#888',
			},
		},
	};
	return <ReactEChartsComponent option={option} props={props} />;
};
export default InvoicedChartComponent;
