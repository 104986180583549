import { Divider, Flex, Stack, Text } from '@chakra-ui/react';
import { formatNumber, formatOrDash } from '../../../../../utils/utils';
import {
	getAnnualInvestmentReturn,
	getCapitalGainOnSale,
	getEstimatedTotalROI,
} from '../../../../../utils/projectDataUtils';
import numeral from 'numeral';

const CapitalGrowthBoxInfo = ({ propertyInfo }) => {
	const estimatedResalePrice = numeral(
		propertyInfo?.estimatedResalePrice,
	).value();
	const appreciation = numeral(propertyInfo?.appreciation).value();

	const estimatedTotalROI = getEstimatedTotalROI(propertyInfo);

	const annualInvestmentReturn = getAnnualInvestmentReturn(propertyInfo);
	const subcategory = propertyInfo?.subcategory;

	return (
		<Stack
			bg='transparencyWhite.100'
			borderRadius='12px'
			p='15px 20px'
			gap='15px'
		>
			<Text
				variant='colored'
				fontSize='20px'
				fontWeight='700'
				lineHeight='20px'
				letterSpacing={-0.02}
			>
				{subcategory !== 'Rent & Rent'
					? 'Capital Growth and Estimated Returns'
					: 'Estimated Returns'}
			</Text>
			<Stack py='9px' gap='9px'>
				{subcategory !== 'Rent & Rent' && (
					<>
						<Flex justifyContent='space-between'>
							<Text
								variant='gray'
								fontWeight='400'
								fontSize='12px'
								lineHeight='20px'
								letterSpacing={-0.02}
							>
								Net Resale Price (After Fees and Taxes)
							</Text>
							<Text
								variant='gray'
								fontSize='12px'
								lineHeight='20px'
								letterSpacing={-0.02}
							>
								{formatOrDash(estimatedResalePrice)}
							</Text>
						</Flex>
						<Divider />
						<Flex justifyContent='space-between'>
							<Text
								variant='gray'
								fontWeight='400'
								fontSize='12px'
								lineHeight='20px'
								letterSpacing={-0.02}
							>
								Appreciation
							</Text>
							<Text
								variant='gray'
								fontSize='12px'
								lineHeight='20px'
								letterSpacing={-0.02}
							>
								{!isNaN(formatNumber(appreciation))
									? `${formatNumber(appreciation)} %`
									: '-'}
							</Text>
						</Flex>
						<Divider />
					</>
				)}
				{subcategory !== 'Rent & Rent' && (
					<>
						<Flex justifyContent='space-between'>
							<Text
								fontWeight='700'
								fontSize='12px'
								lineHeight='20px'
								letterSpacing={-0.02}
							>
								Capital Gain on Sale
							</Text>
							<Text
								fontWeight='700'
								fontSize='12px'
								lineHeight='20px'
								letterSpacing={-0.02}
							>
								{formatOrDash(
									getCapitalGainOnSale(propertyInfo),
								)}
							</Text>
						</Flex>
						<Divider />
					</>
				)}
				<Flex justifyContent='space-between'>
					<Text
						fontWeight='700'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						Estimated Total ROI
					</Text>
					<Text
						fontWeight='700'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						{formatNumber(estimatedTotalROI) !== 'NaN'
							? `${formatNumber(estimatedTotalROI)} %`
							: '-'}
					</Text>
				</Flex>
				<Divider />
				<Flex justifyContent='space-between'>
					<Text
						fontWeight='700'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						Annual Investment Return
					</Text>
					<Text
						fontWeight='700'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						{formatNumber(annualInvestmentReturn) !== 'NaN'
							? `${formatNumber(annualInvestmentReturn)} %`
							: '-'}
					</Text>
				</Flex>
			</Stack>
		</Stack>
	);
};

export default CapitalGrowthBoxInfo;
