import { Divider, Flex, Stack, Text } from '@chakra-ui/react';
import { formatOrDash } from '../../../../../utils/utils';
import { getElevexFee, getTotal } from '../../../../../utils/projectDataUtils';
import numeral from 'numeral';

const ProjectCostSummaryBoxInfo = ({ propertyInfo }) => {
	const category = propertyInfo?.category;
	const subcategory = propertyInfo?.subcategory;
	const taxesAndOperatingCosts = propertyInfo?.taxesAndOperatingCosts;
	const currentPropertyValue = propertyInfo?.currentPropertyValue;
	const renovationAndFitoutsCosts = propertyInfo?.renovationAndFitoutsCosts;

	return (
		<Stack
			bg='transparencyWhite.100'
			borderRadius='12px'
			p='15px 20px'
			gap='15px'
		>
			<Text
				variant='colored'
				fontSize='20px'
				fontWeight='700'
				lineHeight='20px'
				letterSpacing={-0.02}
			>
				Project Cost Summary
			</Text>
			<Stack py='9px' gap='9px'>
				<Flex justifyContent='space-between'>
					<Text
						variant='gray'
						fontWeight='400'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						{subcategory === 'Rent & Rent'
							? 'Annual Rental Cost'
							: 'Current Property Value'}
					</Text>
					<Text
						variant='gray'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						{formatOrDash(numeral(currentPropertyValue).value())}
					</Text>
				</Flex>
				<Divider />
				<Flex justifyContent='space-between'>
					<Text
						variant='gray'
						fontWeight='400'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						Taxes and Operating Costs
					</Text>
					<Text
						variant='gray'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						{formatOrDash(numeral(taxesAndOperatingCosts).value())}
					</Text>
				</Flex>
				<Divider />
				<Flex justifyContent='space-between'>
					<Text
						variant='gray'
						fontWeight='400'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						{category === 'Build'
							? 'Construction Costs'
							: 'Renovation and Fitouts Costs'}
					</Text>
					<Text
						variant='gray'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						{formatOrDash(
							numeral(renovationAndFitoutsCosts).value(),
						)}
					</Text>
				</Flex>
				<Divider />
				<Flex justifyContent='space-between'>
					<Text
						variant='gray'
						fontWeight='400'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						Elevex Fee
					</Text>
					<Text
						variant='gray'
						fontWeight='400'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						{formatOrDash(getElevexFee(propertyInfo))}
					</Text>
				</Flex>
				<Divider />
				<Flex justifyContent='space-between'>
					<Text
						fontWeight='700'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						Total
					</Text>
					<Text
						fontWeight='700'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						{formatOrDash(getTotal(propertyInfo))}
					</Text>
				</Flex>
			</Stack>
		</Stack>
	);
};

export default ProjectCostSummaryBoxInfo;
