import { IconUSDC, IconEther, IconUSDT, IconTRNSK } from '../assets/Icons';
import { ENV } from '../constants/constants';
import DetailsTab from '../pages/ProjectDetails/components/ProjectMoreInfo/DetailsTab/DetailsTab';
import DocumentsTab from '../pages/ProjectDetails/components/ProjectMoreInfo/DocumentsTab/DocumentsTab';
import FinancialSummaryTab from '../pages/ProjectDetails/components/ProjectMoreInfo/FinancialSummaryTab/FinancialSummaryTab';
import ProjectUpdatesTab from '../pages/ProjectDetails/components/ProjectMoreInfo/ProjectUpdatesTab/ProjectUpdatesTab';

/* -------- Discovery -------- */

// Filters:

const alphabeticalFilters = [
	{ group: 'alphabetic', name: 'Default', value: '' },
	{ group: 'alphabetic', name: 'A-Z', value: 'asc' },
	{ group: 'alphabetic', name: 'Z-A', value: 'desc' },
];
const statusFilters = [
	{ group: 'propertyStatus', name: 'All', value: '' },
	{ group: 'propertyStatus', name: 'Upcoming', value: 'Upcoming' },
	{ group: 'propertyStatus', name: 'Open', value: 'Open' },
	{
		group: 'propertyStatus',
		name: 'Funded',
		value: 'Funded',
	},
	{
		group: 'propertyStatus',
		name: 'Open Market',
		value: 'OpenMarket',
	},
];

const typesFilters = [
	{ group: 'category', name: 'All', value: '' },
	{ group: 'category', name: 'Rental', value: 'Rental' },
	{ group: 'category', name: 'Flip', value: 'Flip' },
	{ group: 'category', name: 'Build', value: 'Build' },
];
const oldFilters = [
	{ group: 'date', name: 'Default', value: '' },
	{ group: 'date', name: 'Newest To Oldest', value: 'desc' },
	{ group: 'date', name: 'Oldest To Newest', value: 'asc' },
];
const priceFilters = [
	{ group: 'price', name: 'Default', value: '' },
	{ group: 'price', name: 'Price High To Low', value: 'desc' },
	{ group: 'price', name: 'Price Low To High', value: 'asc' },
];

/* -------- Wallet -------- */

// SendTokensComponent:

const tokenList = [
	{
		token: 'Ethereum',
		abbr: 'ETH',
		icon: IconEther,
	},
	{
		token: ENV === 'dev' ? 'Transak Token' : 'Tether',
		abbr: 'USDT',
		icon: ENV === 'dev' ? IconTRNSK : IconUSDT,
	},
	{
		token: 'USD Coin',
		abbr: 'USDC',
		icon: IconUSDC,
	},
];
const pageSizeCount = [
	{ label: '10', value: 10 },
	{ label: '15', value: 15 },
	{ label: '20', value: 20 },
	{ label: 'all', value: 'all' },
];

/* -------- AddProperty -------- */

// Info tab menu options:
// const purchasedOrPledgeOptions = ['Purchased', 'Pledge'];
const purchasedOrPledgeOptions = ['Purchased'];
const propertyStatusOptions = [
	'Upcoming',
	'Open',
	'Funded-Open Market',
	'Cancelled-Refund',
	'Ended-Burned',
];
const projectCategoryOptions = ['Rental', 'Flip', 'Build'];
const buildSubcategories = ['Build & Rent', 'Build & Sell'];
const rentalSubcategories = [
	'Buy & Rent',
	'Buy, Renovate & Rent',
	'Buy Off-Plan & Rent',
	'Rent & Rent',
];
const flipSubcategories = [
	'Buy & Sell',
	'Buy, Renovate & Sell',
	'Buy Off-Plan & Sell',
];

const postedByOptions = ['Elevex'];

const propertyTypeOptions = [
	'Villa',
	'Apartment',
	'Building',
	'Townhouse',
	'Cottage',
	'Hotel',
];

const categorySubcategoryRelation = {
	Rental: rentalSubcategories,
	Flip: flipSubcategories,
	Build: buildSubcategories,
};

// Financial Details menu Tab:

const KYCOptions = {
	Enable: true,
	Disabled: false,
};

const distributionFrequencyOptions = [
	'Monthly',
	'Quarterly',
	'Annually',
	'Date of sale',
];

const documentLanguageOptions = ['en', 'es'];

const projectStatus = {
	Pending: 'Pending',
	Funded: 'Funded',
	Refund: 'Refund',
	Draft: 'Draft',
	Reload: 'Reload',
	Published: 'Published',
	Ended: 'Ended',
};

const propertyStatus = {
	Upcoming: 'Upcoming',
	Open: 'Open',
	Funded: 'Funded',
	OpenMarket: 'Open Market',
	Cancelled: 'Cancelled',
	Burned: 'Burned',
	EndedBurned: 'Ended-Burned',
};

const logActionOptions = {
	Buy: 'buy',
	Sell: 'sell',
	Send: 'send',
	Receive: 'receive',
	Claim: 'claim',
	Create: 'create',
	Mint: 'mint',
};

const logTypeOptions = {
	Fiat: 'fiat',
	Crypto: 'crypto',
};

const buttonActions = {
	Refund: 'Refund',
	Withdraw: 'Withdraw',
	Publish: 'Publish',
};

const menuProjectDetails = [
	{
		key: 'details',
		label: 'details',
		component: DetailsTab,
	},
	{
		key: 'documents',
		label: 'documents',
		component: DocumentsTab,
		propsKey: 'documents',
	},
	{
		key: 'financialSummary',
		label: 'financialSummary',
		component: FinancialSummaryTab,
	},
	{
		key: 'projectUpdates',
		label: 'projectUpdates',
		component: ProjectUpdatesTab,
		propsKey: 'updates',
	},
];

const TrendFilter = {
	ClaimedAmount: 'claimedAmount',
	AvgMonthlyIncome: 'avgMonthlyIncome',
	ROI: 'roi',
	ExitedProjects: 'exitedProjects',
	Invested: 'invested',
	OwnedProjects: 'ownedProjects',
};

export {
	alphabeticalFilters,
	statusFilters,
	typesFilters,
	oldFilters,
	priceFilters,
	tokenList,
	purchasedOrPledgeOptions,
	propertyStatusOptions,
	projectCategoryOptions,
	buildSubcategories,
	rentalSubcategories,
	flipSubcategories,
	propertyTypeOptions,
	postedByOptions,
	KYCOptions,
	distributionFrequencyOptions,
	documentLanguageOptions,
	categorySubcategoryRelation,
	projectStatus,
	propertyStatus,
	pageSizeCount,
	logActionOptions,
	logTypeOptions,
	buttonActions,
	menuProjectDetails,
	TrendFilter,
};
