import {
	Flex,
	Grid,
	GridItem,
	Text,
	useColorMode,
	useMediaQuery,
} from '@chakra-ui/react';
import BalanceComponent from './components/BalanceComponent';
import MyPropertiesComponent from './components/MyPropertiesComponent';
import OverviewComponent from './components/OverviewComponent';
import IncomeComponent from './components/IncomeComponent';
import TransactionComponent from './components/TransactionComponent';
import TrendComponent from './components/TrendComponent';
import TokenHoldingsComponent from './components/TokenHoldingsComponent';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import useProject from '../../hooks/useProject';
import { updateUser } from '../../redux/states/user';
import { getBalances } from '../../services/walletService';
import { getUserDashboardData } from '../../services/userService';
import { TrendFilter } from '../../data/optionsData';

const Dashboard = () => {
	const { t } = useTranslation();
	const [isTablet] = useMediaQuery('(max-width: 1140px)');
	const user = useSelector(state => state.user);
	const { colorMode } = useColorMode();
	const { getUserProjects } = useProject();
	const dispatch = useDispatch();
	const [graphDays, setGraphDays] = useState(30);
	const [graphData, setGraphData] = useState([]);
	const [graphFilter, setGraphFilter] = useState(TrendFilter.ClaimedAmount);

	const bgStyle =
		colorMode === 'dark'
			? 'transparencyWhite.100'
			: 'transparencyWhite.950';

	useMemo(async () => {
		const data = await getUserDashboardData(graphDays, graphFilter);
		let dates = [];

		if (graphDays > 90) {
			dates = data?.data?.map(item => item.date);
		} else {
			dates = data?.data?.map(item => {
				const date = new Date(item.date);
				const month = String(date.getMonth() + 1).padStart(2, '0');
				const day = String(date.getDate()).padStart(2, '0');
				return `${month}-${day}`;
			});
		}

		const values = data?.data?.map(item => item.value);
		setGraphData({ dates, values });
	}, [graphDays, graphFilter]);

	useEffect(() => {
		if (!user.projects) {
			const getUserProjectsFromBlockchain = async () => {
				try {
					const projects = await getUserProjects(user.walletAddress);

					dispatch(updateUser({ projects }));
				} catch (error) {
					console.error(error);
				}
			};

			const balancesget = async () => {
				const balances = await getBalances(user.walletAddress);
				dispatch(updateUser(balances));
			};

			if (user.walletAddress.length > 0) balancesget();
			getUserProjectsFromBlockchain();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Flex
				flexDirection={isTablet ? 'column' : 'row'}
				justifyContent='space-between'
				align='center'
				mb='12px'
				gap='12px'
				w='100%'
			>
				<Text fontSize={32} fontWeight='700'>
					{t('dashboard.title')}
				</Text>

				<BalanceComponent user={user} isTablet={isTablet} />
			</Flex>
			<Grid
				templateColumns={
					isTablet ? '1fr' : '355px repeat(2, minmax(0, 1fr))'
				}
				templateRows={isTablet ? 'auto' : '165px 270px 380px'}
				rowGap='14px'
				columnGap='12px'
			>
				<GridItem
					rowSpan={3}
					colSpan={isTablet ? 3 : 1}
					w={isTablet ? '100%' : '100%'}
					display='flex'
					justifyContent='center'
				>
					<MyPropertiesComponent projects={user.projects} />
				</GridItem>
				<GridItem
					colSpan={isTablet ? 3 : 1}
					display='flex'
					justifyContent='center'
				>
					<OverviewComponent
						ownedProjects={user?.projects?.length}
						isTablet={isTablet}
					/>
				</GridItem>
				<GridItem
					colSpan={isTablet ? 3 : 1}
					display='flex'
					justifyContent='center'
					w='100%'
				>
					<IncomeComponent />
				</GridItem>
				<GridItem
					colSpan={isTablet ? 2 : 1}
					display='flex'
					justifyContent='center'
				>
					<TransactionComponent isTablet={isTablet} />
				</GridItem>

				<GridItem
					colSpan={isTablet ? 3 : 1}
					display='flex'
					justifyContent='center'
					bg={bgStyle}
					border='0.5px solid'
					borderColor={'transparencyBlack.200'}
					borderRadius='12px'
				>
					<TrendComponent
						isTablet={isTablet}
						data={graphData}
						graphDays={graphDays}
						setGraphDays={setGraphDays}
						setGraphFilter={setGraphFilter}
						graphFilter={graphFilter}
					/>
				</GridItem>
				<GridItem
					colSpan={isTablet ? 3 : 2}
					display='flex'
					justifyContent='center'
					mb={isTablet ? '60px' : '0px'}
				>
					<TokenHoldingsComponent
						isTablet={isTablet}
						projects={user.projects}
					/>
				</GridItem>
			</Grid>
		</>
	);
};

export default Dashboard;
