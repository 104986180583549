import {
	Chart as ChartJS,
	LineElement,
	CategoryScale,
	LinearScale,
	PointElement,
	Filler,
	Tooltip,
	Legend,
} from 'chart.js';
import {
	Button,
	Flex,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Stack,
	Text,
} from '@chakra-ui/react';

import { ChevronDownIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import InvoicedChartComponent from '../../Dashboard/components/InvoicedChartComponent';

ChartJS.register(
	LineElement,
	CategoryScale,
	LinearScale,
	PointElement,
	Filler,
	Tooltip,
	Legend,
);

const GraphComponent = ({ data, setGraphDays, graphDays }) => {
	const { t } = useTranslation();

	return (
		<Stack h='340px' w='100%' p='15px 20px' gap='5px'>
			<Text
				fontSize='16'
				fontWeight='700'
				lineHeight='12px'
				letterSpacing={0.02}
			>
				{t('adminPage.volumeTransactions.title')}
			</Text>
			<Flex justifyContent='space-between' align='center'>
				<Flex gap='3px' flexDirection={{ base: 'column', sm: 'row' }}>
					{/* <Text
						variant='colored'
						fontSize='12'
						fontWeight='400'
						lineHeight='17px'
					>
						(+5){t('adminPage.volumeTransactions.more')}
					</Text>
					<Text
						variant='gray'
						fontSize='12'
						fontWeight='400'
						lineHeight='17px'
					>
						{t('adminPage.volumeTransactions.year')}
					</Text> */}
				</Flex>

				<Menu>
					<MenuButton
						as={Button}
						rightIcon={<ChevronDownIcon boxSize='20px' />}
						borderRadius='27px'
						fontSize='10px'
						px='10px'
						w='fit-content'
						h='22px'
					>
						{t(`adminPage.volumeTransactions.${graphDays}`)}
					</MenuButton>
					<MenuList fontSize={10}>
						<MenuItem onClick={() => setGraphDays(30)}>
							{t('adminPage.volumeTransactions.30')}
						</MenuItem>
						<MenuItem onClick={() => setGraphDays(90)}>
							{t('adminPage.volumeTransactions.90')}
						</MenuItem>
						<MenuItem onClick={() => setGraphDays(180)}>
							{t('adminPage.volumeTransactions.180')}
						</MenuItem>
						<MenuItem onClick={() => setGraphDays(365)}>
							{t('adminPage.volumeTransactions.365')}
						</MenuItem>
						<MenuItem onClick={() => setGraphDays(730)}>
							{t('adminPage.volumeTransactions.730')}
						</MenuItem>
					</MenuList>
				</Menu>
			</Flex>

			<InvoicedChartComponent
				dashboard={false}
				heigh='100%'
				data={data}
			/>
		</Stack>
	);
};

export default GraphComponent;
