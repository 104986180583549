import {
	Button,
	Divider,
	Flex,
	Grid,
	GridItem,
	Link,
	Skeleton,
	Spacer,
	Stack,
	Text,
	useColorMode,
	useDisclosure,
	useMediaQuery,
	useTheme,
	VStack,
} from '@chakra-ui/react';
import BoxComponent from '../../../components/BoxComponent';
import OfferInfoComponent from './OfferInfoComponent';
import { useNavigate } from 'react-router-dom';
import { PrivateRoutes } from '../../../routes/Routes';
import { useTranslation } from 'react-i18next';
import PaginationComponent from '../../../components/PaginationComponent';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from '../../../redux/states/user';
import IconReload from '../../../assets/Icons/components/IconReload';
import useProject from '../../../hooks/useProject';
import useClaim from '../../../hooks/useClaim';
import SendProjectTokensComponent from '../../../components/SendProjectTokensComponent';
import TooltipComponent from '../../../components/TooltipTableComponent';
import useSeller from '../../../hooks/useSeller';
import AlertDialogSuccesfulTransaction from '../../../components/AlertDialogSuccesfulTransaction';

/**
*
    @name TokenHoldingsComponent
    @description Component that list the offers that are avaliable for the user in this moment and shows how many time remains for the next payment
	@returns {JSX.Element} TokenHoldingsComponent.js
    @author Edunknown | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

const TokenHoldingsComponent = ({ isTablet, projects }) => {
	const { t } = useTranslation();
	const [isMobile] = useMediaQuery('(max-width: 450px)');
	const navigate = useNavigate();
	const theme = useTheme();
	const user = useSelector(state => state.user);
	const {
		isOpen: isOpenSendModal,
		onOpen: onOpenSendModal,
		onClose: onCloseSendModal,
	} = useDisclosure();
	const [selectedProject, setSelectedProject] = useState();
	const { colorMode } = useColorMode();
	const [page, setPage] = useState(1);
	const [userProjects, setUserProjects] = useState([]);
	const [isLoadingInformation, setIsLoadingInformation] = useState(false);
	const { getUserProjects } = useProject();
	const { checkUnclaimed, claim } = useClaim();
	const [isRowLoading, setRowIsLoading] = useState(false);
	const [txHash, setTxHash] = useState(undefined);
	const {
		isOpen: isOpenSuccesfullModal,
		onOpen: onOpenSuccessfulModal,
		onClose: onCloseSuccessfulModal,
	} = useDisclosure();

	const [errorMessage, setErrorMessage] = useState('');

	const [isRefreshing, setIsRefreshing] = useState(false);
	const { refund } = useSeller();

	const dispatch = useDispatch();

	const eraseProjectsFromArray = metadataId => {
		const _projects = projects.filter(
			item => item.project.metadataId !== metadataId,
		);
		setUserProjects(_projects.slice(0, 5));
		dispatch(updateUser({ projects: _projects }));
	};

	useEffect(() => {
		const checkUnclaimedProjects = async visibleProjects => {
			if (visibleProjects?.length > 0) {
				setIsLoadingInformation(true);
				await updateCheckedProjectsStatus(visibleProjects);
				setIsLoadingInformation(false);
			}
		};
		let visibleProjects = [];
		if (projects) {
			visibleProjects = projects.slice((page - 1) * 4, page * 4);
			setUserProjects(visibleProjects);
		}
		checkUnclaimedProjects(visibleProjects);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);

	const handleRefund = async metadataId => {
		if (errorMessage !== '') {
			setErrorMessage('');
		}
		if (!isOpenSuccesfullModal) {
			onOpenSuccessfulModal();
		}
		setRowIsLoading(metadataId);
		const refundResponse = await refund(user.walletAddress, metadataId);
		if (refundResponse.success) {
			eraseProjectsFromArray(metadataId);
			setRowIsLoading(undefined);
			setTxHash(refundResponse.receipt.hash);
			return refundResponse.receipt;
		}
	};

	const closeSuccessfulModal = () => {
		setTxHash(undefined);
		onCloseSuccessfulModal();
	};

	const handleRefresh = async () => {
		setIsRefreshing(true);
		const projects = await getUserProjects(user.walletAddress);

		if (projects) {
			const userProjects = projects.slice((page - 1) * 4, page * 4);
			const checkedProjects = await checkUnclaimed(
				userProjects,
				user.walletAddress,
			);

			setUserProjects(checkedProjects);
			dispatch(updateUser({ projects }));
		}
		setIsRefreshing(false);
	};

	const handleSendModal = metadataId => {
		const _project = projects.find(
			project => project.project.metadataId === metadataId,
		);
		setSelectedProject(_project);
		onOpenSendModal();
	};

	const handleClaimTokens = async metadataId => {
		onOpenSuccessfulModal();
		setRowIsLoading(metadataId);
		const claimResponse = await claim({
			walletAddress: user.walletAddress,
			metadataId,
		});

		if (claimResponse.success) {
			setTxHash(claimResponse.receipt.hash);
			await updateCheckedProjectsStatus();
		}
		setRowIsLoading(undefined);
		return claimResponse.success && claimResponse.receipt;
	};

	const updateCheckedProjectsStatus = async visibleProjects => {
		const checkedProjects = await checkUnclaimed(
			visibleProjects,
			user.walletAddress,
		);

		setUserProjects(checkedProjects);
	};

	const colorBorderTablet =
		colorMode === 'dark'
			? theme.colors.transparencyWhite[100]
			: theme.colors.transparencyBlack[100];
	const colorBorderDesktop =
		colorMode === 'dark'
			? theme.colors.transparencyWhite[100]
			: theme.colors.transparencyBlack[200];

	return (
		<BoxComponent w='100%' h='100%' p='0' inPadding='15px' gap='11px'>
			<Flex justifyContent='space-between' alignItems='center'>
				<Text
					fontWeight='700'
					fontSize={isMobile ? '14px' : '16px'}
					lineHeight='100%'
					letterSpacing={0.02}
				>
					{t('dashboard.tokenHoldings.title')}
				</Text>
				<Button
					variant='secondary'
					fontWeight='400'
					fontSize='13px'
					py='10px'
					pl='5px'
					pe='10px'
					gap='5px'
					onClick={handleRefresh}
					isLoading={isRefreshing}
				>
					<IconReload boxSize='20px' color='white' />
					Refresh
				</Button>
			</Flex>
			<Flex
				flexDirection='column'
				justifyContent='flex-start'
				h='100%'
				gap='5px'
				mb={isMobile ? '15px' : '0px'}
			>
				<Grid
					templateColumns='repeat(4, 1fr)'
					placeItems='center'
					textAlign='center'
					fontSize={isTablet ? '12px' : '14px'}
					border={isTablet ? '2px solid' : 'none'}
					borderColor={colorBorderTablet}
					borderRadius={isTablet ? '12px' : '0'}
					rowGap={isTablet ? '0' : '0'}
				>
					<GridItem
						borderRight={
							isTablet ? `2px solid ${colorBorderTablet}` : 'none'
						}
						px='5px'
						py={isTablet ? '12px' : '5px'}
						w='100%'
						h='100%'
					>
						<Text
							fontSize={isTablet ? '12px' : '14px'}
							fontWeight='700'
							lineHeight='100%'
							wordBreak='break-all'
							variant='colored'
							textAlign={'start'}
							mb={isTablet ? '0px' : '6px'}
						>
							{t('dashboard.tokenHoldings.name')}
						</Text>
					</GridItem>
					<GridItem
						borderRight={
							isTablet ? `2px solid ${colorBorderTablet}` : 'none'
						}
						px='5px'
						py={isTablet ? '12px' : '5px'}
						w={isMobile ? '50px' : '100%'}
						h='100%'
					>
						{isMobile ? (
							<TooltipComponent
								label={t('dashboard.tokenHoldings.totalToken')}
								fontSize={isTablet ? '12px' : '14px'}
								fontWeight='700'
								lineHeight='100%'
								variant='colored'
								mb={isTablet ? '0px' : '6px'}
								wordBreak='break-all'
							/>
						) : (
							<Text
								fontSize={isTablet ? '12px' : '14px'}
								fontWeight='700'
								lineHeight='100%'
								variant='colored'
								mb={isTablet ? '0px' : '6px'}
								wordBreak='break-all'
								cursor={'default'}
								isTruncated
							>
								{t('dashboard.tokenHoldings.totalToken')}
							</Text>
						)}
					</GridItem>
					<GridItem
						borderRight={
							isTablet ? `2px solid ${colorBorderTablet}` : 'none'
						}
						px='5px'
						py={isTablet ? '12px' : '5px'}
						w='100%'
						h='100%'
					>
						<Text
							fontSize={isTablet ? '12px' : '14px'}
							fontWeight='700'
							lineHeight='100%'
							variant='colored'
							mb={isTablet ? '0px' : '6px'}
							wordBreak='break-all'
						>
							{t('dashboard.tokenHoldings.value')}
						</Text>
					</GridItem>
					<GridItem
						borderRight={
							isTablet ? `2px solid ${colorBorderTablet}` : 'none'
						}
						px='5px'
						py={isTablet ? '12px' : '5px'}
						w='100%'
						h='100%'
					>
						<Text
							fontSize={isTablet ? '12px' : '14px'}
							fontWeight='700'
							lineHeight='100%'
							variant='colored'
							mb={isTablet ? '0px' : '6px'}
							wordBreak='break-all'
						>
							{t('dashboard.tokenHoldings.projectEstimatedROI')}
						</Text>
					</GridItem>
					<GridItem
						py={isTablet ? '12px' : '5px'}
						px='5px'
						w='100%'
						h='100%'
					>
						<Text
							fontSize={isTablet ? '12px' : '14px'}
							fontWeight='700'
							lineHeight='100%'
							variant='colored'
							mb={isTablet ? '0px' : '6px'}
							wordBreak='break-all'
						>
							{t('dashboard.tokenHoldings.action')}
						</Text>
					</GridItem>
					<Divider
						gridColumn={isTablet ? 'span 5' : 'span 6'}
						borderWidth='1px'
						borderColor={colorBorderDesktop}
					/>

					{!userProjects ? (
						<Stack>
							<Skeleton height='198.45px' />
							<Skeleton height='198.45px' />
							<Skeleton height='198.45px' />
						</Stack>
					) : userProjects.length === 0 ? (
						<GridItem
							py={isTablet ? '24px' : '16px'}
							px='2px'
							colSpan={5}
						>
							<Text>{t('dashboard.myProperties.noTokens')}</Text>
						</GridItem>
					) : (
						userProjects.map((offer, index) => {
							return (
								<OfferInfoComponent
									key={index}
									data={offer}
									last={index === userProjects.length - 1}
									isTablet={isTablet}
									colorBorder={colorBorderDesktop}
									walletAddress={user.walletAddress}
									index={index}
									handleClaimTokens={handleClaimTokens}
									eraseFromArray={eraseProjectsFromArray}
									isLoadingInformation={isLoadingInformation}
									isRowLoading={
										offer.project.metadataId ===
										isRowLoading
									}
									handleRefresh={handleRefresh}
									handleRefund={handleRefund}
									onOpenSendModal={handleSendModal}
									onCloseSuccessfulModal={
										closeSuccessfulModal
									}
									isOpenSuccesfullModal={
										isOpenSuccesfullModal
									}
									txHash={txHash}
								/>
							);
						})
					)}
				</Grid>
				<Spacer />
				{projects && projects.length > 4 && (
					<VStack
						justify={'center '}
						alignItems={'center'}
						spacing={0}
					>
						<PaginationComponent
							totalProjects={projects.length}
							page={page}
							setPage={setPage}
							pageSize={4}
						/>
						<Link
							variant='isActive'
							fontSize='13'
							fontWeight='400'
							letterSpacing={0.02}
							textAlign='center'
							textDecoration='underline'
							onClick={() => navigate(`/${PrivateRoutes.Wallet}`)}
						>
							{t('dashboard.tokenHoldings.viewAll')}
						</Link>
					</VStack>
				)}
			</Flex>
			{isOpenSendModal && (
				<SendProjectTokensComponent
					isOpenSendModal={isOpenSendModal}
					closeSendModal={onCloseSendModal}
					isMobile={isTablet}
					project={selectedProject?.project}
				/>
			)}
			<AlertDialogSuccesfulTransaction
				isOpen={isOpenSuccesfullModal}
				text={t(
					'projectDetails.offerDetails.succesful.transactionSuccess.description',
				)}
				txHash={txHash}
				errorMessage={errorMessage}
				onClose={onCloseSuccessfulModal}
				onClick={handleRefund}
			/>
		</BoxComponent>
	);
};

export default TokenHoldingsComponent;
