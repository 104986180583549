import { useRef, useEffect } from 'react';
import { init, getInstanceByDom } from 'echarts';
import { Box } from '@chakra-ui/react';

export const ReactEChartsComponent = ({
	option,
	style,
	settings,
	loading,
	theme,
	props,
	heigh,
}) => {
	const chartRef = useRef(null);

	useEffect(() => {
		let chart;
		if (chartRef.current !== null) {
			chart = init(chartRef.current, theme);
		}
		const resizeChart = () => {
			chart?.resize();
		};
		window.addEventListener('resize', resizeChart);

		// Return cleanup function
		return () => {
			chart?.dispose();
			window.removeEventListener('resize', resizeChart);
		};
	}, [theme]);

	useEffect(() => {
		if (chartRef.current !== null) {
			const chart = getInstanceByDom(chartRef.current);
			chart.setOption(option, settings);
		}
	}, [option, settings, theme]);

	useEffect(() => {
		if (chartRef.current !== null) {
			const chart = getInstanceByDom(chartRef.current);
			loading === true ? chart.showLoading() : chart.hideLoading();
		}
	}, [loading, theme]);

	return (
		<Box
			ref={chartRef}
			w={'100%'}
			h={'100%'}
			{...props}
			style={{ ...style }}
		/>
	);
};
