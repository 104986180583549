import { Divider, Flex, Stack, Text } from '@chakra-ui/react';
import { formatNumber, formatOrDash } from '../../../../../utils/utils';
import {
	getAnnualRentalYield,
	getEstimatedMonthlyPayment,
} from '../../../../../utils/projectDataUtils';
import numeral from 'numeral';

const RentalIncomeBoxInfo = ({ propertyInfo }) => {
	const rentalTimeline = propertyInfo?.rentalTimeline;
	const estimatedAnnualIncomeFromRental = numeral(
		propertyInfo?.estimatedAnnualIncomeFromRental,
	).value();
	const distributionFrequency = propertyInfo?.distributionFrequency;
  
	const category = propertyInfo?.category ?? 'Project';

	const subcategory = propertyInfo?.subcategory;

	const annualRentalYield = getAnnualRentalYield(propertyInfo);

	return (
		<Stack
			bg='transparencyWhite.100'
			borderRadius='12px'
			p='15px 20px'
			gap='15px'
		>
			<Text
				variant='colored'
				fontSize='20px'
				fontWeight='700'
				lineHeight='20px'
				letterSpacing={-0.02}
			>
				{`${category} Income Overview`}
			</Text>
			<Stack py='9px' gap='9px'>
				{(category === 'Rental' ||
					(category === 'Build' &&
						subcategory.toLowerCase().includes('rent'))) && (
					<>
						<Flex justifyContent='space-between'>
							<Text
								variant='gray'
								fontWeight='400'
								fontSize='12px'
								lineHeight='20px'
								letterSpacing={-0.02}
							>
								Estimated Annual Net Income from Rental
							</Text>
							<Text
								variant='gray'
								fontSize='12px'
								lineHeight='20px'
								letterSpacing={-0.02}
							>
								{formatOrDash(estimatedAnnualIncomeFromRental)}
							</Text>
						</Flex>
						<Divider />
						<Flex justifyContent='space-between'>
							<Text
								variant='gray'
								fontWeight='400'
								fontSize='12px'
								lineHeight='20px'
								letterSpacing={-0.02}
							>
								Rental Timeline
							</Text>
							<Text
								variant='gray'
								fontSize='12px'
								lineHeight='20px'
								letterSpacing={-0.02}
							>
								{!isNaN(parseInt(rentalTimeline))
									? `${parseInt(rentalTimeline)} Month(s)`
									: '-'}
							</Text>
						</Flex>
					</>
				)}

				<Divider />
				<Flex justifyContent='space-between'>
					<Text
						variant='gray'
						fontWeight='400'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						Frequency of Distribution
					</Text>
					<Text
						variant='gray'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						{distributionFrequency}
					</Text>
				</Flex>
				<Divider />
				{subcategory !== 'Build & Sell' && (
					<>
						<Flex justifyContent='space-between'>
							<Text
								variant='gray'
								fontWeight='400'
								fontSize='12px'
								lineHeight='20px'
								letterSpacing={-0.02}
							>
								Rental Timeline
							</Text>
							<Text
								variant='gray'
								fontSize='12px'
								lineHeight='20px'
								letterSpacing={-0.02}
							>
								{!isNaN(parseInt(rentalTimeline))
									? `${parseInt(rentalTimeline)} Month(s)`
									: '-'}
							</Text>
						</Flex>
					</>
				)}

				<Divider />
				<Flex justifyContent='space-between'>
					<Text
						variant='gray'
						fontWeight='400'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						Frequency of Distribution
					</Text>
					<Text
						variant='gray'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						{distributionFrequency}
					</Text>
				</Flex>
				<Divider />
				<Flex justifyContent='space-between'>
					<Text
						variant='gray'
						fontWeight='400'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						Estimated Monthly Payment
					</Text>
					<Text
						variant='gray'
						fontWeight='400'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						{formatOrDash(getEstimatedMonthlyPayment(propertyInfo))}
					</Text>
				</Flex>
				<Divider />
				<Flex justifyContent='space-between'>
					<Text
						variant='gray'
						fontWeight='400'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						Annual Rental Yield
					</Text>
					<Text
						variant='gray'
						fontWeight='400'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						{formatNumber(annualRentalYield) !== 'NaN'
							? `${formatNumber(annualRentalYield)} %`
							: '-'}
					</Text>
				</Flex>
			</Stack>
		</Stack>
	);
};

export default RentalIncomeBoxInfo;
