// LineChart.js
import {
	Chart as ChartJS,
	LineElement,
	CategoryScale,
	LinearScale,
	PointElement,
	Filler,
	Tooltip,
	Legend,
} from 'chart.js';
import {
	Button,
	Flex,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Stack,
	Text,
} from '@chakra-ui/react';
import { IconArrowDown } from '../../../assets/Icons';
import { useTranslation } from 'react-i18next';
import InvoicedChartComponent from './InvoicedChartComponent';
import { TrendFilter } from '../../../data/optionsData';

/**
*
    @name TrendComponent
    @description Component used to generate a chart of the data that we provide. We can filter by time and by type
				 It uses Chart.js and react-chart.js
	@returns {JSX.Element} TrendComponent.js
    @author Edunknown | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

ChartJS.register(
	LineElement,
	CategoryScale,
	LinearScale,
	PointElement,
	Filler,
	Tooltip,
	Legend,
);

const TrendComponent = ({
	data,
	setGraphDays,
	graphDays,
	setGraphFilter,
	graphFilter,
}) => {
	const { t } = useTranslation();

	const daysSelection = {
		30: '30days',
		90: '90days',
		180: '6Months',
		365: 'year',
		730: '2years',
	};

	return (
		<Stack h='325px' w='100%' p='15px 20px' gap='5px'>
			<Text fontSize='16' fontWeight='700'>
				{t('dashboard.trend.title')}
			</Text>
			<Flex justifyContent='space-between' align='center'>
				<Flex gap='3px' flexDirection={{ base: 'column', sm: 'row' }}>
					{/* <Text
					variant='colored'
					fontSize='12'
					fontWeight='400'
					lineHeight='17px'
				>
					(+5){t('adminPage.volumeTransactions.more')}
				</Text>
				<Text
					variant='gray'
					fontSize='12'
					fontWeight='400'
					lineHeight='17px'
				>
					{t('adminPage.volumeTransactions.year')}
				</Text> */}
				</Flex>

				<Flex
					flexDirection={{ base: 'column', sm: 'row' }}
					align='center'
					gap='10px'
				>
					<Menu>
						<MenuButton
							as={Button}
							rightIcon={<IconArrowDown boxSize='10px' />}
							borderRadius='27px'
							fontSize='10px'
							px='10px'
							w='fit-content'
							h='22px'
						>
							{t(`dashboard.trend.${daysSelection[graphDays]}`)}
						</MenuButton>
						<MenuList fontSize={10}>
							<MenuItem onClick={() => setGraphDays(30)}>
								{t('dashboard.trend.30days')}
							</MenuItem>
							<MenuItem onClick={() => setGraphDays(90)}>
								{t('dashboard.trend.90days')}
							</MenuItem>
							<MenuItem onClick={() => setGraphDays(180)}>
								{t('dashboard.trend.6Months')}
							</MenuItem>
							<MenuItem onClick={() => setGraphDays(365)}>
								{t('dashboard.trend.year')}
							</MenuItem>
							<MenuItem onClick={() => setGraphDays(730)}>
								{t('dashboard.trend.2years')}
							</MenuItem>
						</MenuList>
					</Menu>
					<Menu>
						<MenuButton
							as={Button}
							rightIcon={<IconArrowDown boxSize='10px' />}
							borderRadius='27px'
							fontSize='10px'
							px='10px'
							w='fit-content'
							h='22px'
						>
							{t(`dashboard.trend.${graphFilter}`)}
						</MenuButton>
						<MenuList fontSize={10}>
							<MenuItem
								onClick={() =>
									setGraphFilter(TrendFilter.ClaimedAmount)
								}
							>
								{t('dashboard.trend.claimedAmount')}
							</MenuItem>
							<MenuItem
								onClick={() =>
									setGraphFilter(TrendFilter.ExitedProjects)
								}
							>
								{t('dashboard.trend.exitedProjects')}
							</MenuItem>
							<MenuItem
								onClick={() =>
									setGraphFilter(TrendFilter.Invested)
								}
							>
								{t('dashboard.trend.invested')}
							</MenuItem>
							<MenuItem
								onClick={() =>
									setGraphFilter(TrendFilter.OwnedProjects)
								}
							>
								{t('dashboard.trend.ownedProjects')}
							</MenuItem>
						</MenuList>
					</Menu>
				</Flex>
			</Flex>

			<InvoicedChartComponent dashboard={true} heigh='100%' data={data} />
		</Stack>
	);
};

export default TrendComponent;
