import { Contract, JsonRpcProvider } from 'ethers';

import { convertUSDCinUSD, getUSDCBalance } from './usdcService';
import { convertUSDTinUSD, getUSDTBalance } from './usdtServices';
import { oracleABI } from '../interfaces/oracle.abi';
import { convertEthereuminUSD, getEthereumBalance } from './ethereumServices';
import { getNumberWithDecimals } from '../utils/utils';
import axios from 'axios';

const rpc = process.env.REACT_APP_RPC;
export const provider = new JsonRpcProvider(rpc);
const oracleAddress = process.env.REACT_APP_CONTRACT_ADDRESS_ORACLE;
export const oracle = await new Contract(oracleAddress, oracleABI, provider);

const currenciesInUSD = async () => {
	try {
		const currenciesPrice = await axios.get('/currencieBalancesInUSD');
		return currenciesPrice?.data?.data;
	} catch (error) {
		console.error('Error getting the currencies in USD:', error);
		return false;
	}
};

const formatBalance = async (balance, decimals = 2) => {
	if (balance !== 0) {
		const formattedBalance = getNumberWithDecimals(balance, decimals);
		return formattedBalance;
	}
};

export const formatBalanceWallet = async balance => {
	return balance === '0.000' ? '0' : balance;
};

export const getWalletBalances = async walletAddress => {
	try {
		const currenciePrices = await currenciesInUSD();

		const [ethBalance, usdtBalance, usdcBalance] = await Promise.all([
			getEthereumBalance(walletAddress),
			getUSDTBalance(walletAddress),
			getUSDCBalance(walletAddress),
		]);

		const [
			formattedEthBalance,
			formattedUSDCBalance,
			formattedUSDTBalance,
		] = await Promise.all([
			formatBalance(ethBalance, 5),
			formatBalance(usdcBalance),
			formatBalance(usdtBalance),
		]);

		const [ethInUsd = 0, usdcInUsd, usdtInUsd] = await Promise.all([
			convertEthereuminUSD(formattedEthBalance, currenciePrices.ETH),
			convertUSDCinUSD(formattedUSDCBalance, currenciePrices.USDC),
			convertUSDTinUSD(formattedUSDTBalance, currenciePrices.USDT),
		]);

		return {
			eth: formattedEthBalance,
			usdc: formattedUSDCBalance,
			usdt: formattedUSDTBalance,
			ethInUsd,
			usdcInUsd,
			usdtInUsd,
		};
	} catch (error) {
		console.error(
			'Error getting the wallet balances (getWalletBalances):',
			error,
		);
	}
};

export const getBalances = async walletAddress => {
	const walletBalances = await getWalletBalances(walletAddress);
	const usdBalance = await getUSDBalanceInWallet(walletBalances);

	return { usdBalance, ...walletBalances };
};

export const convertTokenInUsd = async ({ token, amount }) => {
	const currenciePrices = await currenciesInUSD();

	switch (token) {
		case 'eth':
			return convertEthereuminUSD(amount, currenciePrices.ETH);
		case 'usdc':
			return convertUSDCinUSD(amount, currenciePrices.USDC);
		case 'usdt':
			return convertUSDTinUSD(amount, currenciePrices.USDT);
		default:
			return 0;
	}
};

export const getUSDBalanceInWallet = async balances => {
	const currenciePrices = await currenciesInUSD();
	const [ethBalance, usdcBalance, usdtBalance] = await Promise.all([
		convertEthereuminUSD(balances.eth, currenciePrices.ETH),
		convertUSDCinUSD(balances.usdc, currenciePrices.USDC),
		convertUSDCinUSD(balances.usdt, currenciePrices.USDT),
	]);

	return formatBalance(ethBalance + usdcBalance + usdtBalance);
};
