export const getUserBalanceByCurrency = (currencyType, balances) => {
	const { ethInUsd = 0, usdcInUsd, usdtInUsd } = balances;
	let currencyBalance;

	switch (currencyType) {
		case 'USDT':
			currencyBalance = usdtInUsd;
			break;
		case 'USDC':
			currencyBalance = usdcInUsd;
			break;
		case 'ETH':
			currencyBalance = ethInUsd;
			break;
		default:
			break;
	}
	return currencyBalance;
};
