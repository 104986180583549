import {
	Accordion,
	AccordionButton,
	AccordionItem,
	AccordionPanel,
	Box,
	Flex,
	Text,
	useColorModeValue,
} from '@chakra-ui/react';
import { IconArrowDown } from '../../../assets/Icons';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

const DisclaimerComponent = () => {
	const { t } = useTranslation();
	const theme = useTheme();
	const [expandedIndex, setExpandedIndex] = useState(false);

	// Colores dinámicos
	const primaryColors = useColorModeValue(
		theme.colors.brand[500],
		theme.colors.brand[200],
	);

	const backgroundButton = useColorModeValue(
		theme.colors.transparencyWhite[800],
		theme.colors.transparencyWhite[100],
	);

	return (
		<Accordion allowToggle>
			<AccordionItem
				w={'100%'}
				border={
					expandedIndex
						? `2px solid ${primaryColors}`
						: `1px solid ${theme.colors.transparencyBlack[200]}`
				}
				_focusWithin={{
					borderColor: expandedIndex
						? `2px solid ${primaryColors}`
						: `1px solid ${theme.colors.transparencyBlack[200]}`,
				}}
			>
				<AccordionButton
					pt='20px'
					pb={expandedIndex ? '0' : '20px'}
					onClick={() => setExpandedIndex(!expandedIndex)}
				>
					<Box
						as='span'
						flex='1'
						textAlign='left'
						fontWeight='medium'
					>
						{t(
							'projectDetails.projectMoreInfo.detailsTab.faqs.discleimer.title',
						)}
					</Box>

					<Flex
						w='50px'
						h='50px'
						borderRadius='50%'
						align='center'
						justify='center'
						bg={expandedIndex ? primaryColors : backgroundButton}
					>
						<IconArrowDown
							transition='transform 0.5s'
							boxSize='20px'
							transform={
								expandedIndex
									? 'rotate(0deg)'
									: 'rotate(-90deg)'
							}
							color={expandedIndex && 'white'}
						/>
					</Flex>
				</AccordionButton>

				<AccordionPanel pb='15px'>
					<Text fontSize='16px' fontWeight='400' lineHeight='30px'>
						{t(
							'projectDetails.projectMoreInfo.detailsTab.faqs.discleimer.text1',
						)}
					</Text>
					<Text fontSize='16px' fontWeight='400' lineHeight='30px'>
						{t(
							'projectDetails.projectMoreInfo.detailsTab.faqs.discleimer.text2',
						)}
					</Text>
					<Text fontSize='16px' fontWeight='400' lineHeight='30px'>
						{t(
							'projectDetails.projectMoreInfo.detailsTab.faqs.discleimer.text3',
						)}
					</Text>
					<Text fontSize='16px' fontWeight='400' lineHeight='30px'>
						{t(
							'projectDetails.projectMoreInfo.detailsTab.faqs.discleimer.text4',
						)}
					</Text>
					<Text fontSize='16px' fontWeight='400' lineHeight='30px'>
						{t(
							'projectDetails.projectMoreInfo.detailsTab.faqs.discleimer.text5',
						)}
					</Text>
					<Text fontSize='16px' fontWeight='400' lineHeight='30px'>
						{t(
							'projectDetails.projectMoreInfo.detailsTab.faqs.discleimer.text1',
						)}
						{t(
							'projectDetails.projectMoreInfo.detailsTab.faqs.discleimer.text2',
						)}
						{t(
							'projectDetails.projectMoreInfo.detailsTab.faqs.discleimer.text3',
						)}
						{t(
							'projectDetails.projectMoreInfo.detailsTab.faqs.discleimer.text4',
						)}
						{t(
							'projectDetails.projectMoreInfo.detailsTab.faqs.discleimer.text5',
						)}
					</Text>
				</AccordionPanel>
			</AccordionItem>
		</Accordion>
	);
};

export default DisclaimerComponent;
