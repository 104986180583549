import {
	Box,
	Button,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
	useColorMode,
	useMediaQuery,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { IconArrowDown } from '../../../../assets/Icons';
import { useState } from 'react';
import { menuProjectDetails } from '../../../../data/optionsData';

const ProjectMoreInfo = ({ projectInfo }) => {
	const { t } = useTranslation();
	const [isMobile] = useMediaQuery('(max-width: 600px)');
	const { colorMode } = useColorMode();
	const [currentTab, setCurrentTab] = useState(menuProjectDetails[0].key);

	const renderMobileContent = () => {
		const activeTab = menuProjectDetails.find(
			tab => tab.key === currentTab,
		);
		if (!activeTab) return null;

		const Component = activeTab.component;
		const props = activeTab.propsKey
			? { [activeTab.propsKey]: projectInfo[activeTab.propsKey] }
			: { projectInfo };
		return <Component {...props} />;
	};

	const menuButtonStyles = {
		w: '100%',
		p: '10px',
		fontWeight: '200',
		borderRadius: '5px',
		textAlign: 'start',
		bg:
			colorMode === 'dark'
				? 'transparencyWhite.100'
				: 'transparencyBlack.100',
		_hover: {
			bg:
				colorMode === 'dark'
					? 'transparencyWhite.200'
					: 'transparencyBlack.200',
		},
		_active: {
			border: '0.5px solid',
			borderColor: colorMode === 'dark' ? 'brand.200' : 'brand.500',
		},
	};

	return (
		<>
			{isMobile ? (
				<>
					<Menu>
						<MenuButton
							{...menuButtonStyles}
							as={Button}
							rightIcon={<IconArrowDown boxSize='12px' />}
						>
							<Text fontSize='16px'>
								{t(
									`projectDetails.projectMoreInfo.${currentTab}`,
								)}
							</Text>
						</MenuButton>
						<MenuList fontSize='12px' zIndex={10}>
							{menuProjectDetails.map(option => (
								<MenuItem
									key={option.key}
									onClick={() => setCurrentTab(option.key)}
								>
									<Text>
										{t(
											`projectDetails.projectMoreInfo.${option.label}`,
										)}
									</Text>
								</MenuItem>
							))}
						</MenuList>
					</Menu>
					<Box mt='20px'>{renderMobileContent()}</Box>
				</>
			) : (
				<Tabs>
					<TabList>
						{menuProjectDetails.map(option => (
							<Tab
								key={option.key}
								fontSize={isMobile ? '13px' : 'none'}
							>
								{t(
									`projectDetails.projectMoreInfo.${option.label}`,
								)}
							</Tab>
						))}
					</TabList>
					<TabPanels>
						{menuProjectDetails.map(option => {
							const Component = option.component;
							const props = option.propsKey
								? {
										[option.propsKey]:
											projectInfo[option.propsKey],
									}
								: { projectInfo };

							return (
								<TabPanel
									key={option.key}
									p={0}
									pt={
										option.key === 'documents'
											? '10px'
											: '30px'
									}
								>
									<Component {...props} />
								</TabPanel>
							);
						})}
					</TabPanels>
				</Tabs>
			)}
		</>
	);
};

export default ProjectMoreInfo;
